import * as d3Base from "d3";
import * as d3Multi from "d3-selection-multi";

const d3 = Object.assign(d3Base, d3Multi);

const question = "Do vTPA programs or certification bodies share data/ info with your organization?";

const colScale = d3.scaleOrdinal()
                  .domain([
                    "Yes, vTPA programmes share information",
                    "Yes, certification bodies share information",
                    "No",
                    "No, but planning this for the future",
                    "Don't know"
                  ])
                  .range([
                    d3.rgb('#33CC99'),
                    d3.rgb('#009999'),
                    d3.rgb('#FF6666').darker(),
                    d3.rgb('#FF6666'),
                    d3.rgb('#990066 ')
                  ]);

function drawDonut(data) {
  const height = 600;
  const width = 600;
  const transDur = 750;

  const svgG = d3.select('svg.donut')
                  .append('g')
                  .attr('class', 'donutGrp')
                  .attr('transform', `translate(${width/2}, ${height/2})`);

  const percLabelFontSize = 45;

  const arcLabel = svgG.append('text')
                      .attr('class', 'percLabel')
                      .text('0%')
                      .attr('dy', `${percLabelFontSize/2}px`)
                      .style('fill', 'black')
                      .style('fill-opacity', 0)
                      .style('text-anchor', 'middle')
                      .style('font-size', `${percLabelFontSize}px`);

  const arc = d3.arc()
                .innerRadius(180)
              	.outerRadius(220)
                .padAngle(0.005);

  const pie = d3.pie()
                .value(d => d.value)
                .sort(null);

  function createDatum(d) {
    const clone = Object.assign({}, d);
    clone.endAngle = clone.startAngle;
    return clone;
  }


  svgG.style('opacity', '0')
  .transition()
  .duration('250')
  .style('opacity', '1')

  svgG.selectAll('path.arc')
    .data(pie(data))
    .enter()
    .append('path')
    .attr('class', 'arc')
    .style('fill', d => colScale(d.data.head))
    .transition()
    .delay((d, i) => i * transDur)
    .duration(transDur)
    .attrTween('d', d => {
      const datum = createDatum(d);
      return function(t){
        const endAngleInterp = d3.interpolate(datum, d);
          return arc(endAngleInterp(t));
      }
    });

  //const arcCents = pie(data).map(d => arc(d))
  const labels = [
    [
      {text: "Yes, vTPA", idx: 0},
      {text: "programmes share", idx: 0},
      {text: "information", idx: 0}
    ],
    [
      {text: "Yes, certification", idx: 1},
      {text: "bodies share", idx: 1},
      {text: "information", idx: 1}
    ],
    [
      {text: "No, but ", idx: 2},
      {text: "planning for ", idx: 2},
      {text: "the future", idx: 2}
    ],
    [
      {text: "No", idx: 3}
    ],
    [
      {text: "Don't Know", idx: 4}
    ]
  ];
  const cents = pie(data).map(d => arc.centroid(d));

  const offSet = [[55, -55], [70, -10], [35, 35], [-40, 0], [-10, -33]];
  const text = svgG.selectAll('text.textLab')
    .data(pie(data))
    .enter()
    .append('text')
    .attr('class', 'textLab')
    .style('fill', d => '#212121')
    .style('fill-opacity', 0)
    .style('text-anchor', 'middle')
    .style('font-family', "'Barlow Condensed', sans-serif")
    .style('font-weight', '400')
    //.text(d => d.data.head)
    .attr('x', (d ,i) => arc.centroid(d)[0])
    .attr('y', (d, i) => arc.centroid(d)[1])
    .attr('transform', (d, i) => `translate(${offSet[i]})`);

  text.transition()
    .delay((d, i) => i * transDur)
    .duration(transDur)
    .style('fill-opacity', 1);

  const dy = 0;
  text.selectAll('tspan')
          .data((d, i) => labels[i])
          .enter()
          .append('tspan')
          .text(d => d.text)
          //.attr('x', )
          .attr('x', (d, i) => cents[d.idx][0])
          .attr('dy', (d, i) => i == 0 ? `${dy}px` : `18px`);


  function activateMO(){
    svgG.selectAll('path.arc').on('mouseover', function(d, i){
      const perc = (d3.select(this).datum().data.value / 62) * 100;
      //console.log(round2Dec(perc, 1));
      svgG.select('text.percLabel')
          .text(`${round2Dec(perc, 1)}%`)
          .transition('percLabTrans')
          .style('fill-opacity', 1);
    });

    svgG.selectAll('path.arc').on('mouseout', function(d, i){
      svgG.select('text.percLabel')
          .text(``)
          .style('fill-opacity', 0);
    });

  }

  activateMO();
};


d3.select('#reAnim').on('click', function(d, i){
  // remove everything
  d3.select('svg.donut').selectAll("*").remove();

//  drawDonut(data)

});

function round2Dec(number, dec){
  return Math.round(number * 10**dec)/ 10**dec
}

export { drawDonut }
