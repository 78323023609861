import React, { Component } from 'react';
import styles from './css-modules/footer.module.css';
import STDF_logo from '../resources/landing/landing-stdf_logo.png';
import UNIDO_logo from '../resources/landing/UNIDO.png';
import Report from '../resources/STDF.png';
import pdf from '../resources/STDF_IICA_UNIDO_vTPA_Survey_Report.pdf'

class Footer extends Component {
  render () {
    return (
      <div className={styles.container}>
        <div className={styles.flexed_text_container} onClick={() => window.open(pdf)}>
          <div className={styles.text_container}>
            <p className={styles.text}>{this.props.title}</p>
          </div>
          <div className={styles.report_container}>
            <div className={styles.report_image}>
              <img style={{cursor: 'pointer'}} src={Report} className={styles.report_image_src}></img>
            </div>
          </div>

          <div className={styles.absolute_colored_border}>
            <div className={styles.absolute_border_yellow}>
            </div>
            <div className={styles.absolute_border_red}>
            </div>
          </div>
        </div>
        <div className={styles.white_background}>
          <div className={styles.white_flexed_cont}>
            <div className={styles.logo_container}>
              <img style={{cursor: 'pointer'}} src={STDF_logo} onClick={() => window.open('https://www.standardsfacility.org/')} className={styles.stdf_logo}></img>
              <img style={{cursor: 'pointer'}} src={UNIDO_logo} onClick={() => window.open('https://www.unido.org/')} className={styles.unido_logo}></img>
            </div>

            <p className={styles.copyright_text}>{this.props.copyright_text}</p>
          </div>
        </div>
      </div>
    )
  }
}

export default Footer;
