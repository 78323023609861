import React, { Component } from 'react';
import styles from './css-modules/matrix.module.css';
import { drawMatrix } from './d3/matrix.js';


class Matrix extends Component {

  componentDidMount() {
    drawMatrix();
  }

  render () {
    return (
      <div className={styles.container}>
        <svg className ="correlMatrix" preserveAspectRatio="xMinYMin meet" viewBox = "0 0 920 820"  ></svg>
      </div>
    )
  }
}

export default Matrix;
