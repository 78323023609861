import * as d3Base from "d3";
import * as d3Multi from "d3-selection-multi";
import * as d3Sankey from "d3-sankey";
import csv from './summQ23.csv';

const d3 = Object.assign(d3Base, d3Multi, d3Sankey);

async function readAndDraw(){
  const data = await d3.csv(csv);

  const width = 500;
  const height = 600;

  // getting all nodes - appending q2 and q3 to the labels to make the nodes unique
  const q2Heads = unique(data.map(d => `Q2-${d.q2}`));
  const q3Heads = unique(data.map(d => `Q3-${d.q3}`));
  const nodesAll = [...q2Heads, ...q3Heads];

  // node objects from an array
  const nodes = nodesAll.map(d => {
    return {
      name: d
    }
  });

  // getting links
  const links= data.map(d => {
    return {
      source: nodesAll.indexOf(`Q2-${d.q2}`),
      target: nodesAll.indexOf(`Q3-${d.q3}`),
      names: [d.q2, d.q3],
      value: d.n
    }
  })

  const graph = {
    nodes,
    links
  }

  // visual padding
  const leftRightPad = 10;
  const topBottomPad = 5;

  // define the sankey function
  var sankey = d3.sankey()
    .nodeSort(null)
    .linkSort(null)
    .nodeWidth(10)
    .nodePadding(4)
    .extent([[leftRightPad, topBottomPad], [width - leftRightPad, height - topBottomPad]]);


  const svg = d3.select("svg.sankySVG")
                //.attr("viewBox", `0 0 ${width} ${height}`)
                .append('g')
                .attr('class', 'sankyG');


  let sankyGraph = sankey(graph);

  const colScale = d3.scaleOrdinal().domain([
    "Q2-Don't Know",
    "Q2-No",
    "Q2-Yes",
    "Q3-Don't Know",
    "Q3-No, and not actively considering this for the future",
    "Q3-No, but actively considering this for the future",
    "Q3-Yes"
  ])
  .range([
    "#990066",
    "#FF6666",
    "#009999",
    "#990066",
    "#FF6666",
    "#CC9966",
    "#009999"
  ]);

  const linearGrads = svg.append('defs')
                        .selectAll('linearGradient')
                        .data(links)
                        .join('linearGradient')
                          .attr('id', (d, i) => `id-${i}`)
                          .attr('x1', '0%')
                          .attr('y1', '0%')
                          .attr('x2', '100%')
                          .attr('y2', '0%');

  linearGrads.selectAll('stop')
        .data(d => [
          {offset: '0%', color: d3.rgb(colScale(d.source.name)).darker(-0.4)},
          {offset: '100%', color: d3.rgb(colScale(d.target.name)).darker(-0.4)}
        ])
        .join("stop")
        .attr('offset', d => d.offset)
        .attr('stop-color', d => d.color);

  const ribbons = svg.append("g")
      .attr("fill", "none")
    .selectAll("path.ribbon")
    .data(links)
    .join("path")
      .attr('class', 'ribbon')
      .attr("d", d3.sankeyLinkHorizontal())
      .attr("stroke", (d, i) => i == 0 ? d3.rgb('#FF6666').darker(-.4) : `url(#id-${i})`)
      //.attr("stroke", (d, i) => `url(#id-${i})`)
      .attr("stroke-width", d => d.width)
      .style('stroke-opacity', 0.8)
      .style("mix-blend-mode", "multiply");

  const numLabels = svg.append("g")
                    .attr('class', 'numLabG')
                    .selectAll('text.numLabel')
                    .data(links)
                    .join("text")
                    .attr('class', 'numLabel')
                    .text(d => d.value)
                    .attr('x', width/2)
                    .attr('y', d => (d.y1 + d.y0)/2 + 6)
                    .style('text-anchor', 'middle')
                    .style('font-family', "'Barlow Condensed', sans-serif")
                    .style('font-size', '16px')
                    .style('font-weight', '500')
                    .style('fill', 'white')
                    .style('fill-opacity', 0)
                    //.style('stroke-opacity', 0);

  const nodeG = svg.append("g")
    .attr('class', 'sankyG')
    .selectAll("g.nodeG")
    .data(sankyGraph.nodes)
    .join("g")
    .attr('class', 'nodeG');

  nodeG
      .append("rect")
      .attr("x", d => d.x0)
      .attr("y", d => d.y0)
      .attr("height", d => d.y1 - d.y0)
      .attr("width", d => d.x1 - d.x0)
      .style("fill", d => d3.rgb(colScale(d.name)).darker(0.0))
    // .append("title")
    //   .text(d => `${d.name}\n${d.value.toLocaleString()}`);

  nodeG.append("g")
      .style("font", "14px sans-serif")
      .style("font-family", "'Barlow Condensed', sans-serif")
      .style("font-weight", 300)
      .append("text")
      .attr("x", d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
      .attr("y", d => (d.y1 + d.y0) / 2)
      .attr("dy", "0.35em")
      .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
      .text(d => removeQNo(d.name));

  // nodeG.on('mouseover', function(d, i){
  //   const datum = d3.select(this).datum();
  //
  //   nodeTrans(
  //     nodeG,
  //     ribbons,
  //     sankyGraph,
  //     datum,
  //     true
  //   );
  // });

  // ribbons.on('mouseover', function(d, i){
  //   const datum = d3.select(this).datum();
  //   const nodeNames = [datum.source.name, datum.target.name];
  //
  //   ribbonTrans(
  //     ribbons,
  //     numLabels,
  //     nodeG,
  //     datum,
  //     nodeNames,
  //     true
  //   );
  //
  // })
  //
  // nodeG.on('mouseout', function(d, i){
  //   const datum = d3.select(this).datum();
  //
  //   nodeTrans(
  //     nodeG,
  //     ribbons,
  //     sankyGraph,
  //     datum,
  //     false
  //   );
  // });
  //
  // ribbons.on('mouseout', function(d, i){
  //   const datum = d3.select(this).datum();
  //   const nodeNames = [datum.source.name, datum.target.name];
  //
  //   ribbonTrans(
  //     ribbons,
  //     numLabels,
  //     nodeG,
  //     datum,
  //     nodeNames,
  //     false
  //   );
  // });

  switchStateSankey(2);

}

function nodeTrans(nodeGSelect, ribbonSelect, sankyGraphData, datum, mouseOv){
  const filtLinks = sankyGraphData.links.filter(d => d.source.name == datum.name | d.target.name == datum.name);
  const nodeNames = unique([
    ...filtLinks.map(d => d.source.name),
    ...filtLinks.map(d => d.target.name)]
  );

  const rectSel = nodeGSelect.filter(d => nodeNames.includes(d.name));
  const rectUnsel = nodeGSelect.filter(d => !nodeNames.includes(d.name));

  const ribbonSel = ribbonSelect.filter(d => d.source.name == datum.name | d.target.name == datum.name);
  const ribbonUnsel = ribbonSelect.filter(d => d.source.name != datum.name & d.target.name != datum.name);

  rectUnsel.transition('rectTrans')
      .style('fill-opacity', d => mouseOv ? 0.3 : 1)


  ribbonUnsel.transition()
      .style('stroke-opacity', d => mouseOv ? 0.3 : 1);

  ribbonSel.transition()
      .style('stroke-opacity', d => mouseOv ? 1 : 1);

  rectSel.select('text')
      .transition('textTrans')
      .style('font-size', d => mouseOv ? '16px' : '14px')
      .style('font-weight', d => mouseOv ? '500' : '300')
      .style('fill-opacity', 1);
}

function ribbonTrans(ribbonSelect, numLabelSelect, nodeGSelect, datum, nodeNames, mouseOv){

  const ribbonSel = ribbonSelect.filter(d => d.source.name == datum.source.name & d.target.name == datum.target.name);
  const ribbonUnsel = ribbonSelect.filter(d => d.source.name != datum.source.name | d.target.name != datum.target.name);

  const labelSel = numLabelSelect.filter(d => d.source.name == datum.source.name & d.target.name == datum.target.name);
  const labelUnsel = numLabelSelect.filter(d => d.source.name != datum.source.name | d.target.name != datum.target.name);

  const rectSel = nodeGSelect.filter(d => nodeNames.includes(d.name));
  const rectUnsel = nodeGSelect.filter(d => !nodeNames.includes(d.name));

  ribbonUnsel.transition()
      .style('stroke-opacity',  d => mouseOv ? 0.3 : 1);

  ribbonSel.transition()
      .style('stroke-opacity',  d => mouseOv ? 1 : 1);

  labelSel.transition()
      .style('fill-opacity', d => mouseOv ? 1 : 0)
      .style('stroke-opacity', d => mouseOv ? 1 : 0);

  rectSel//.selectAll('rect')
      .transition('rectTrans')
      .style('fill-opacity', 1);

  rectSel.select('text')
      .transition('textTrans')
      .style('font-size', '14px')
      .style('font-weight', d => mouseOv ? '500' : '300')
      .style('fill-opacity', 1);

  rectUnsel.select('text')
      .transition('textTrans')
      .style('font-size', '14px')
      .style('font-weight', '300')
      .style('fill-opacity', d => mouseOv ? 0.5 : 1);
}

function opacSankey(ribbonSelect, nodeGSelect, numLabelSelect){

  ribbonSelect.style('fill-opacity', 1)
  nodeGSelect.style('fill-opacity', 1);
  nodeGSelect.selectAll('text').style('font-weight', 300);
  numLabelSelect.style('fill-opacity', 0);
}

function removeQNo(optionText){
  return optionText.replace(/Q[2-3]-/i, "");
}

function switchStateSankey(state){
  // get all selections
  const svg = d3.select('svg.sankySVG');

  const ribbonSelect = svg.selectAll('path.ribbon');
  const numLabelSelect = svg.selectAll('text.numLabel');
  const nodeGSelect = svg.selectAll('g.nodeG');

  //console.log("ribbonSelect", ribbonSelect, ribbonSelect.data());

  const datum = ribbonSelect.filter(d => d.source.name == "Q2-Yes" & d.target.name == "Q3-Yes").datum();
  const nodeNames = ["Q2-Yes", "Q3-Yes"];
  const nodeDatum = nodeGSelect.data()[4];

  if (state == 0){
    opacSankey(ribbonSelect, nodeGSelect, numLabelSelect);

    ribbonTrans(ribbonSelect, numLabelSelect, nodeGSelect, datum, nodeNames, true);

    nodeGSelect.on('mouseover', null);
    ribbonSelect.on('mouseover', null);
    nodeGSelect.on('mouseout', null);
    ribbonSelect.on('mouseout', null);
  }

  else if (state == 1){
    opacSankey(ribbonSelect, nodeGSelect, numLabelSelect)

    nodeTrans(
      nodeGSelect,
      ribbonSelect,
      {
        nodes: nodeGSelect.data(),
        links: ribbonSelect.data()
      },
      nodeDatum,
      true
    )

    nodeGSelect.on('mouseover', null);
    ribbonSelect.on('mouseover', null);
    nodeGSelect.on('mouseout', null);
    ribbonSelect.on('mouseout', null);
  }
  else {
    opacSankey(ribbonSelect, nodeGSelect, numLabelSelect)

    ribbonTrans(ribbonSelect, numLabelSelect, nodeGSelect, datum, nodeNames, false);

    nodeGSelect.on('mouseover', function(d, i){
      const datum = d3.select(this).datum();

      nodeTrans(
        nodeGSelect,
        ribbonSelect,
        {
          nodes: nodeGSelect.data(),
          links: ribbonSelect.data()
        },
        datum,
        true
      );
    });

    ribbonSelect.on('mouseover', function(d, i){
      const datum = d3.select(this).datum();
      const nodeNames = [datum.source.name, datum.target.name];

      ribbonTrans(
        ribbonSelect,
        numLabelSelect,
        nodeGSelect,
        datum,
        nodeNames,
        true
      );

    })

    nodeGSelect.on('mouseout', function(d, i){
      const datum = d3.select(this).datum();

      nodeTrans(
        nodeGSelect,
        ribbonSelect,
        {
          nodes: nodeGSelect.data(),
          links: ribbonSelect.data()
        },
        datum,
        false
      );
    });

    ribbonSelect.on('mouseout', function(d, i){
      const datum = d3.select(this).datum();
      const nodeNames = [datum.source.name, datum.target.name];

      ribbonTrans(
        ribbonSelect,
        numLabelSelect,
        nodeGSelect,
        datum,
        nodeNames,
        false
      );
    });
  }
}

// function to hget uynique elements from an array
function unique(arr){
  return arr.filter((d, i) => arr.indexOf(d) == i);
}

export { readAndDraw , switchStateSankey };
