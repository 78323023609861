import React, { Component } from 'react';
import styles from './css-modules/sankey.module.css';
import { readAndDraw , switchStateSankey } from './d3/sankey.js';
import {GiMagnifyingGlass} from "react-icons/gi";
import { MdInfoOutline } from 'react-icons/md';
import Fade from 'react-reveal/Fade';
import FadeMat from '@material-ui/core/Fade';

class Sankey extends Component {
  constructor(props) {
    super(props);
    this.state = {sankeyState: 2, sankeyStateButton1: 0, sankeyStateButton2: 1}
  }

  componentDidMount() {
    readAndDraw();
  }

  handleSankeyChange(buttonType, stateIndex) {
    this.setState({
      sankeyState: stateIndex
    });
    if (buttonType === 'ex1') {
      if (stateIndex === 2) {
        switchStateSankey(0);
        this.setState({
          sankeyStateButton2: 1
        });
      }
      else {
        switchStateSankey(2);
      }
      this.setState({
        sankeyStateButton1: stateIndex
      });
    }
    else {
      if (stateIndex === 2) {
        switchStateSankey(1);
        this.setState({
          sankeyStateButton1: 0
        });
      }
      else {
        switchStateSankey(2);
      }
      this.setState({
        sankeyStateButton2: stateIndex
      });
    }
  }

  render () {
    return (
      <div className={styles.container}>
        <div className={styles.text_svg_wrapper}>
          <div className={styles.mobile_question_text_cont}>
            <p className={styles.mobile_question_text}>Knowledge on existing national government policy, strategy, legislation or regulations related to vTPA programmes in food and feed safety</p>
            <p className={styles.mobile_question_text}>Current recognition, application of and/or use of data generated by vTPA programmes for official control functions for food and feed safety</p>
          </div>
          <div className={styles.svg_questions_wrapper}>
            <p className={styles.question_text}>In your country, is there any national government policy, strategy, legislation or regulation that refers to vTPA programmes in food and feed safety?</p>
            <div className={styles.svg_container}>
              <Fade duration={500} when={this.state.sankeyStateButton1 === 2}>
                <div className={styles.absolute_text_info}>
                  <p className={styles.info}>15 respondents indicated that they have policies or strategies in place providing guidance on the use of vTPA programmes</p>
                </div>
              </Fade>
              <Fade duration={500} when={this.state.sankeyStateButton2 === 2}>
                <div className={styles.absolute_text_info}>
                  <p className={styles.info}>24 respondents indicated that their jurisdiction is considering adopting the vTPA approach</p>
                </div>
              </Fade>
              <div className = {styles.svgWrapper}>
                <svg className ="sankySVG" viewBox = "0 0 500 600" preserveAspectRatio = "xMinYMin meet" width="100%"></svg>
              </div>
            </div>
            <p id={styles.second_question} className={styles.question_text}>Does your organization recognize, take account of and/or use information from vTPA programmes to inform or support any aspects of its official control functions for food and feed safety?</p>
          </div>
        </div>
        <div className={styles.buttons_container}>
          <div className={styles.subscription_button}>
            <FadeMat in={this.state.sankeyStateButton1 === 2}>
              <div onClick={() => this.handleSankeyChange('ex1', 0)} className={styles.button_text_wrapper}>
                <p className={styles.subscription_button_text}>Explore</p>
                <GiMagnifyingGlass size={18} className={styles.subscription_button_icon}/>
              </div>
            </FadeMat>
            <FadeMat in={this.state.sankeyStateButton1 === 0}>
              <div onClick={() => this.handleSankeyChange('ex1', 2)} className={styles.button_text_wrapper}>
                <p className={styles.subscription_button_text}>Innovators</p>
                <MdInfoOutline size={18} className={styles.subscription_button_icon}/>
              </div>
            </FadeMat>
          </div>
          <div className={`${styles.subscription_button} ${styles.margined_sub_button}`}>
            <FadeMat in={this.state.sankeyStateButton2 === 2}>
              <div onClick={() => this.handleSankeyChange('ex2',1)} className={styles.button_text_wrapper}>
                <p className={styles.subscription_button_text}>Explore</p>
                <GiMagnifyingGlass size={18} className={styles.subscription_button_icon}/>
              </div>
            </FadeMat>
            <FadeMat in={this.state.sankeyStateButton2 === 1}>
              <div onClick={() => this.handleSankeyChange('ex2', 2)} className={styles.button_text_wrapper}>
                <p className={styles.subscription_button_text}>Followers</p>
                <MdInfoOutline size={18} className={styles.subscription_button_icon}/>
              </div>
            </FadeMat>
          </div>
        </div>
      </div>
    )
  }
}

export default Sankey;
