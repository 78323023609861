import React, { Component } from 'react';
import styles from './css-modules/landing.module.css';
import Image1 from '../resources/landing/25784838327_99de70db0f_o.jpg';
import Image2 from '../resources/landing/39911644745_362f691b33_o.jpg';
import STDF_logo from '../resources/landing/landing-stdf_logo.png';
import UNIDO_logo from '../resources/landing/UNIDO.png';
import { IoMdArrowRoundDown, IoIosDocument } from "react-icons/io";
import gsap, {TimelineMax, Power3, TweenMax, Sine, CSSPlugin} from 'gsap';
import pdf from '../resources/STDF_IICA_UNIDO_vTPA_Survey_Report.pdf'
gsap.registerPlugin(CSSPlugin);

class Landing extends Component {
  componentDidMount () {
    this.tl = new TimelineMax({ repeat: -1, repeatDelay: 1 });
    this.tl.fromTo(document.getElementById("md_arrow"), 0.75, {
        opacity : '1',
        y: 0,
    }, {
        opacity : 0,
        y: 10,
        ease: Power3.easeInOut,
    }, 0)
    .fromTo(document.getElementById("md_arrow"), 0.75, {
        opacity : '0',
        y: '-15'
    }, {
        opacity : '1',
        y: '0',
        ease: Power3.easeInOut,
    }, 0.75);

    new TimelineMax()
    .fromTo(document.getElementById('container_landing'), 1.05, {
        opacity : '0'
    }, {
        opacity : '1',
        ease: Power3.easeInOut,
    }, 0.075)
    .fromTo(document.getElementById('left_image_landing'), 2, {
        scale : '1'
    }, {
        scale : '1.1',
        ease: Power3.easeInOut,
    }, 0.3)
    .fromTo(document.getElementById('right_image_landing'), 2, {
        scale : '1'
    }, {
        scale : '1.045',
        ease: Power3.easeInOut,
    }, 0.315);
  }

  componentWillUnmount() {
    this.tl.kill();
  }

  render () {
    return (
      <div className={styles.container} id="container_landing">
        <div className={styles.left_image_container}>
          <img id="left_image_landing" src={Image1} className={styles.left_image}></img>
        </div>
        <div className={styles.right_image_container}>
          <div className={styles.right_image_skewCancel}>
            <img id="right_image_landing" src={Image2} className={styles.right_image}></img>
          </div>
        </div>
        <div className={styles.title_container}>
          <div className={styles.title_colored}>
            <div className={styles.icon_container}>
              <img style={{cursor: 'pointer'}} src={STDF_logo} onClick={() => window.open('https://www.standardsfacility.org/')} className={styles.stdf_logo}></img>
              <img style={{cursor: 'pointer'}} src={UNIDO_logo} onClick={() => window.open('https://www.unido.org/')} className={styles.unido_logo}></img>
            </div>
            <div className={styles.colored_title_container}>
              <p className={styles.title}>{this.props.title}</p>
              <p className={styles.description}>{this.props.description}</p>
           </div>
           <div className={styles.bottom_button}>
              <p className={styles.button_text}>SCROLL DOWN</p>
              <div className={styles.scroll_down_icon}>
                <IoMdArrowRoundDown id="md_arrow" className={styles.scroll_down_icon_arrow}/>
              </div>
           </div>
          </div>
          <div className={styles.download_report_button} onClick={() => window.open(pdf)}>
            <p className={styles.download_report_text}>Download Report</p>
            <IoIosDocument/>
          </div>
        </div>
      </div>
    )
  }
}

export default Landing;
