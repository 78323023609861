import * as d3Base from "d3";
import * as d3Multi from "d3-selection-multi";

const d3 = Object.assign(d3Base, d3Multi);


const advan = [
  {head: "Overall improvement of food hygiene and safety", value: 49},
  {head: "Assisting food business in complying with the law", value: 36},
  {head: "Added inspection capacity (private audits)", value: 27},
  {head: "Public controls can focus on high risks", value: 48},
  {head: "Increased confidence in the level of compliance through access to vTPA data", value: 27}
];

const disAdvan = [
  {head: "Private assurance systems are not reliable", value: 23},
  {head: "Conflicts with legal obligations", value: 24},
  {head: "Confidentiality of private audit reports", value: 26},
  {head: "Regulatory capture", value: 22},
  {head: "Undermines consumer confidence in government authorities", value: 24},
  {head: "Financial costs for food business operators", value: 33}
];

const svgWidth = 700;
const svgHeight = 430;

function draw(type, className, data){
  const title = type == "Advantages" ? type : "Concerns"
  const sortedData = data.sort((a, b) => b.value - a.value);

  const margin = {
    top: 110,
    bottom: 10,
    left: 30,
    right: 20
  };

  const width = svgWidth - margin.left - margin.right;
  const height = svgHeight - margin.top - margin.bottom;
  const cellWidth = width/ 64;

  const cellData = Array.from(Array(64).keys()).map(d => d + 1);

  const scaleBand = d3.scaleBand()
    .domain(sortedData.map(d => d.head))
    .range([0, height * (sortedData.length)/6]);

  const svgG = d3.select('svg.' + className)
                .append('g')
                .classed('barCellG', true)
                .attr('transform', `translate(${margin.left}, ${margin.top})`);


  const titleSel = svgG.append('text')
                    .text(title)
                    .attr('x', '0')
                    .attr('y', '-50px')
                    .style('font-size', '24px')
                    .style('font-family', "'Barlow', sans-serif")
                    .style('font-weight', 500);

  const categGroup = svgG.selectAll('g.categGroup')
                        .data(sortedData)
                        .enter()
                        .append('g')
                        .attr('class', 'categGroup')
                        .attr('transform', d => `translate(0, ${scaleBand(d.head)})`);

  const categText = categGroup.append('text')
                              .html(d => `
                                  <tspan class = "head">${d.head} </tspan>
                                  <tspan class = "value"> (<tspan class="valueNum">${0}</tspan>/ 64)</tspan>
                              `)
                              //.style('font-family', "'Roboto', sans-serif")
                              .style('font-family', "'Barlow', sans-serif")
                              .style('font-size', "15px")
                              .style('fill-opacity', 0)
                              .transition()
                              .delay((d, i) => i * 500)
                              .duration(250)
                              .style('fill-opacity', 1);

  const vals = sortedData.map(d => d.value);

  svgG.style('opacity', '0')
    .transition()
    .duration('450')
    .style('opacity', '1')

  svgG.selectAll('tspan.valueNum')
    .style('font-size', "16px")
    .transition()
    .delay((d, i) => i * 500)
    .duration(500)
    .textTween(function(d, i) {
          const int = d3.interpolate(0, vals[i]);
          return function(t) { return Math.round(int(t)); };
        });

  const barCellG = categGroup.append('g')
                        .attr('class', 'barCellG')
                        .attr('transform', d => `translate(0, 10)`);

  const cells = barCellG.selectAll('rect.cells')
                        .data((d, i) => cellDataGen(64, d.value, i))
                        .enter()
                        .append('rect')
                        .attr('class', 'cells')
                        .attr('x', (d, i) => i * cellWidth)
                        .attr('y', 0)
                        .attr('width', cellWidth - 1)
                        .attr('height', scaleBand.bandwidth()/2 - 2)
                        .style('fill', '#eee')
                        //.style('fill-opacity', 0.5)
                        .transition()
                        .delay((d, i) => (i * 20) + (d.parentIdx * 500))
                        .duration(100)
                        .style('fill', d =>  d.colState ? (type == "Advantages" ? '#009999' : '#FF6666') : '#eee')
                        //.style('fill-opacity', 1);
}

function cellDataGen(totalLen, value, parentIdx){
  const arr = Array.from(Array(totalLen).keys()).map(d => d + 1);
  return arr.map(d => {
    return {
      parentIdx: parentIdx,
      idx: d,
      colState: (d > value) ? false : true
    }
  })
}

let count = 0
d3.select('#reAnim').on('click', function(d, i){
  // remove everything
  d3.select('svg.barCell').selectAll("*").remove();

  count += 1;
  (count % 2 == 1) ? draw("Disadvantages") : draw("Advantages");
})

export { draw }
