import React, { Component } from 'react';
import styles from './css-modules/picture_text.module.css';
import simpleParallax from 'simple-parallax-js';
import Fade from 'react-reveal/Fade';


class Picture_Text extends Component {

  componentDidMount() {
    var image = document.getElementById(this.props.image_id);
    if (this.props.orientation === 'right') {
      new simpleParallax(image, {
        orientation: 'right',
        scale: '1.1'
      });
    }
    else if (this.props.orientation === 'left') {
      new simpleParallax(image, {
        orientation: 'left',
        scale: '1.1'
      });
    }
    else {
      new simpleParallax(image, {
        scale: '1.145'
      });
    }
  }

  render () {
    return (
      <div className={styles.container}>
        <img id={this.props.image_id} src={this.props.image} className={styles.parallax_img}></img>
        <div className={styles.gradient_overlay}></div>
        <div className={styles.text_container}>
          <Fade bottom distance={'50px'} duration={1250}>
            <p className={styles.text}>{this.props.text}</p>
          </Fade>
        </div>
        <div className={styles.absolute_border}></div>
      </div>
    )
  }
}

export default Picture_Text;
