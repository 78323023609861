import React from 'react';
import logo from './logo.svg';
import './App.css';
import Picture_Text from './components/pictureText.js';
import Landing from './components/landing.js';
import Sankey from './components/sankey.js';
import Donut from './components/donut.js';
import Matrix from './components/matrix.js';
import BarCell from './components/bar_cell.js';
import Footer from './components/footer.js';
import Image from './resources/29299341147_8ac8b1845a_o.jpg';
import Image1 from './resources/49356724873_1170629dca_o.jpg';
import Image2 from './resources/38880256960_6a3d723db9_o.jpg';
import Tooltip from '@material-ui/core/Tooltip';

function App() {
  return (
    <div className="App">
      <Landing
        title={'A more targeted, risk-based approach for food control'}
        description={<p>This story provides initial insights on how some regulators are using – or considering the use of – reliable data from <span><Tooltip placement="bottom" style={{cursor: 'pointer'}} title="vTPA is an autonomous scheme comprising of the ownership of a standard that may utilise national/international requirements; a governance structure for certification and conformity assessment that provides for periodic onsite audits for food business operator or FBO operations for compliance with the standard, and in which FBO participation is voluntary (Codex)"><i><b>voluntary third-party assurance (vTPA)</b></i></Tooltip></span> programmes, linked to new draft Codex guidelines. It shares key findings from an online survey of WTO SPS Committee delegates and Codex Contact Points.</p>}
      />
      <div className="container">
        <p className="section_title">Correlation across existing policies and strategies on vTPA programmes and the use of data generated by these programmes</p>
        <p className="section_sub_title">Use the button below to show the relationships between the responses</p>
        <div className="title_separator">
          <div className="title_separator_lines"></div>
        </div>
        <Sankey/>
      </div>
      <Picture_Text image={Image} image_id={'image_one'} scale={1.4} text={"A growing number of food safety regulators are interested in access to industry data on the outcomes of private audits. They obtain this data in different ways"}/>
      <div className="container">
        <p className="section_title">Do vTPA programmes or certification bodies share data/information with your organization?</p>
        <div className="title_separator">
          <div className="title_separator_lines"></div>
        </div>
        <Donut/>
      </div>
      <Picture_Text image={Image1} orientation='right' image_id={'image_two'} scale={1.4} text={"Regulatory authorities expect to benefit in different ways from this new approach"}/>
      <div className="container">
        <p className="section_title">Cooperation with vTPA programmes brings benefits, as well as some concerns</p>
        <div className="title_separator">
          <div className="title_separator_lines"></div>
        </div>
        <BarCell/>
      </div>
      <Picture_Text orientation='left' image={Image2} image_id={'image_three'} scale={1.4} text={"To use the vTPA approach, food safety regulators need to consider the availability and trustworthiness of data generated by vTPA programmes"}/>
      <div className="container">
        <p className="section_title">There seems to be some correlation across the scope and type of vTPA programmes, and the way in which they are recognized</p>
        <p className="section_sub_title">Hover over the cells to explore the data and the relationship between responses to different questions</p>
        <div className="title_separator">
          <div className="title_separator_lines"></div>
        </div>
        <div className="margined_vis">
          <Matrix/>
        </div>
      </div>
      <Footer
        title="To learn more about the findings of the STDF/UNIDO/IICA survey, download the report"
        copyright_text="© 2020 Standards and Trade Development Facility - All Rights Reserved"
      />
    </div>
  );
}

export default App;
