import React, { Component } from 'react';
import styles from './css-modules/bar_cell.module.css';
import { draw } from './d3/bar_cell.js';


class Bar_Cell extends Component {

  constructor(props) {
    super(props);
    this.state = { adv_in: false, disadv_in: false }
  }

  componentDidMount() {
    var parent = this;
    window.addEventListener('scroll', function(e) {
      if (!parent.state.adv_in) {
        if (parent.isInViewport(document.getElementById('barCell_adv_container'))) {
          draw("Advantages", 'barCell_adv', [
            {head: "Overall improvement of food hygiene and safety", value: 49},
            {head: "Assisting food business in complying with the law", value: 36},
            {head: "Added inspection capacity (private audits)", value: 27},
            {head: "Public controls can focus on high risks", value: 48},
            {head: "Increased confidence in the level of compliance through access to vTPA data", value: 27}
          ]);
          parent.setState({
            adv_in: true
          })
        }
      }
      if (!parent.state.disadv_in) {
        if (parent.isInViewport(document.getElementById('barCell_adv_container'))) {
          draw("Disadvantages", 'barCell_disAdv', [
            {head: "Private assurance systems are not reliable", value: 23},
            {head: "Conflicts with legal obligations", value: 24},
            {head: "Confidentiality of private audit reports", value: 26},
            {head: "Regulatory capture", value: 22},
            {head: "Undermines consumer confidence in government authorities", value: 24},
            {head: "Financial costs for food business operators", value: 33}
          ]);
          parent.setState({
            disadv_in: true
          })
        }
      }
    });


  }

  isInViewport(elem) {
    var distance = elem.getBoundingClientRect();
    return (
      distance.top - distance.height <= 200
    );
  };

  render () {
    return (
      <div className={styles.container} id="barCells_container">
        <div className={styles.advantage_container} id="barCell_adv_container">
          <svg className ="barCell_adv" preserveAspectRatio="xMinYMin meet" viewBox = "0 0 700 430"></svg>
        </div>
        <div className={styles.disadvantage_container}>
          <svg className ="barCell_disAdv" preserveAspectRatio="xMinYMin meet" viewBox = "0 0 700 430" ></svg>
        </div>
      </div>
    )
  }
}

export default Bar_Cell;
