import React, { Component } from 'react';
import styles from './css-modules/donut.module.css';
import { drawDonut } from './d3/donut.js';


class Sankey extends Component {

  constructor(props) {
    super(props);
    this.state = { donut_drawn: false }
  }


  componentDidMount() {
    var parent = this;
    window.addEventListener('scroll', function(e) {
      if (!parent.state.donut_drawn) {
        if (parent.isInViewport(document.getElementById('donut_container'))) {
          drawDonut([
            {head: "Yes, vTPA programmes share information", value: 10},
            {head: "Yes, certification bodies share information", value: 5},
            {head: "No, but planning this for the future", value: 14},
            {head: "No", value: 30},
            {head: "Don't know", value: 3},
          ]);
          parent.setState({
            donut_drawn: true
          })
        }
      }
    });
  }

  isInViewport(elem) {
    var distance = elem.getBoundingClientRect();
    return (
      distance.top - distance.height <= -10
    );
  };

  render () {
    return (
      <div className={styles.container} id="donut_container">
        <svg className ="donut" preserveAspectRatio="xMinYMin meet" viewBox = "0 0 620 600" ></svg>
      </div>
    )
  }
}

export default Sankey;
